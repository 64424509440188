/* eslint-disable camelcase */
import * as React from 'react';
import { graphql } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from '../../../components/layout';
import ProductPage from '../../../components/products/ProductPage';

export const query = graphql`
  query QuerySingleProduct($id: String) {
    mdx(id: { eq: $id }, fileAbsolutePath: { regex: "/src/mdx/products/" }) {
      frontmatter {
        title
        html_head_title
        description
        parent
        deployments
        logo {
          publicURL
        }
        hero {
          publicURL
        }
        headline
        tagline
        bundle {
          name
          slug
        }
        urls {
          marketplace
          demo
          docs
          migration
        }
        youtube_id
        features {
          title
          text
        }
        feature_images {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body
      slug
    }
  }
`;

const ProductPageWithLayout = ({ data }) => {
  if (!data.mdx) {
    // for blog & policies
    return null;
  }

  const { frontmatter, slug } = data.mdx;
  const { title, html_head_title, headline, tagline, description, parent } =
    frontmatter;

  const pageTitle = html_head_title || title;
  const seoTitle =
    parent === 'monday.com' && !pageTitle.indexOf('monday.com') > 0
      ? `${pageTitle} in ${parent}`
      : pageTitle;
  const metaDescription =
    description && description.length > 0
      ? description
      : headline.length > tagline.length
      ? headline
      : tagline;

  return (
    <>
      <GatsbySeo
        language="en"
        title={seoTitle}
        description={metaDescription}
        openGraph={{
          url: `https://www.dsapps.dev/products/${slug}`,
          title: seoTitle,
          description: metaDescription,
          type: 'product',
        }}
      />
      <Layout pageTitle={seoTitle}>
        <ProductPage data={data} />
      </Layout>
    </>
  );
};

export default ProductPageWithLayout;
